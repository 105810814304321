$(function () {
  // TODO Split out into multiple files, don't register handles for pages we aren't on
  $('.thumbnail').change(function () {
    if (typeof (FileReader) !== 'undefined') {
      var preview = $('.thumbnail_preview')

      preview.html('')

      var files = $(this)[0].files

      if (!files.length) {
        return preview.css('background-image', '')
      }

      var file = $(files[0])[0]

      // TODO show error here if not?
      if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/tiff') {
        var reader = new FileReader()

        reader.onload = function (e) {
          preview.css('background-image', 'url(' + e.target.result + ')').show()
        }

        reader.readAsDataURL(file)
      } else {
        // TODO Set value to nothing?
        preview.css('background-image', '')
      }
    }
  })

  $('#upload .input-group label').click(function (event) {
    var type = $(this).find('input').attr('value')

    if (type === 'minigame' || type === 'adventuremap' || type === 'experience' || type === 'inspiration') {
      $('.resourcepack-box').show()
      $('.recommended-box').show()
      validate_recommended_players($('.first').val(), $('.second').val())
    } else {
      $('.resourcepack-box').hide()
      $('.recommended-box').hide()
      $('.recommended_players').val(null)
    }

    do_validation(event)
  })

  $('.toggle-text').click(function () {
    $(this).find('span').each(function () {
      $(this).toggle()
    })
  })

  $('.stats-button').click(function (event) {
    var stats_button = $(this)
    var map_id = stats_button.data('mapId')
    var stats_block = $('#' + map_id + '-stats')
    if (stats_block.is(':visible')) {
      stats_block.hide()
    } else {
      stats_button.button('loading')
      $.get('/stats/' + map_id).done(function (data) {
        stats_button.button('reset')
        stats_block.html(data)
        stats_block.show()
      }).fail(function (error) {
        stats_button.button('reset')
        if (error.status === 404) {
          stats_block.html('<div class="container"><h3>No stats data available.</h3></div>')
        } else {
          stats_block.html('<div class="container"><h3>Unable to fetch map stats at this time. Please try again later.</h3></div>')
        }
        stats_block.show()
      })
    }
  })

  var get_selected_radio_value = function (button_group_id) {
    return $('#' + button_group_id + ' input:radio:checked').val()
  }

  var load_ranking_table = function (time_range, map_type, criterion) {
    var ranking_table = $('#ranking-table')
    ranking_table.html('<div style="padding-top:100px;text-align:center;"><i class="fas fa-circle-notch fa-spin fa-lg"></i> Loading Leaderboard</div>')
    $.get('/ranking/' + map_type + '/' + criterion + '/' + time_range).done(function (data) {
      ranking_table.html(data)
    }).fail(function (error) {
      ranking_table.html('<div><h3>Unable to fetch map stats at this time. Please try again later.</h3></div>')
    })
  }

  $('#load-ranking').click(function () {
    var time_range = get_selected_radio_value('time-range-button-group')
    var map_type = get_selected_radio_value('map-type-button-group')
    var criterion = get_selected_radio_value('criteria-button-group')
    load_ranking_table(time_range, map_type, criterion)
    history.pushState({
      isLeaderboads: true,
      time_range: time_range,
      map_type: map_type,
      criterion: criterion
    }, '', '/leaderboards/' + map_type + '/' + criterion + '/' + time_range)
  })

  window.onpopstate = function (e) {
    if (e.state && e.state.isLeaderboads) {
      location.replace('/leaderboards/' + e.state.map_type + '/' + e.state.criterion + '/' + e.state.time_range)
    }
  }

  var latest_timestamp

  // TODO Clean this mess up
  // TODO Deduplicate

  var do_validation = _.debounce(function (event) {
    // TODO Replace this to get rid of validator.js dependency
    var values = validate.collectFormValues($('#upload'))
    latest_timestamp = event.timeStamp

    $.post('/validate', values).done(function (data) {
      if (latest_timestamp > event.timeStamp) {
        return
      }

      $('span.error').hide()
      $('input[type=\'submit\']').prop('disabled', false)
    }).fail(function (error) {
      if (latest_timestamp > event.timeStamp) {
        return
      }

      $('span.error').hide()
      if (error.status === 400 && error.responseJSON.fields) {
        for (var field in error.responseJSON.fields) {
          // TODO Only display errors below untouched fields? Or do like DigitalOcean and put a list at the bottom
          $('span.' + field).html(error.responseJSON.fields[field]).show()
        }
      }

      $('input[type=\'submit\']').prop('disabled', true)
    })
  }, 300)

  $('#upload .validate').on('keyup', do_validation)
  $('#upload .thumbnail').change(do_validation)
  $('#upload .file').change(do_validation)
  $('#upload .resourcepack').change(do_validation)
  $('#upload .agreement').click(do_validation)

  var validate_recommended_players = function (first, second) {
    var number = parseInt(first)
    var second_number = second === '+' ? '+' : parseInt(second)

    var elements = [
      0,
      '+',
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10
    ]

    if (number === 0 || number === 10) {
      $('.to').hide()
      $('.second').hide()
    } else {
      $('.to').show()
      $('.second').show()
      $('.second').empty()

      elements.forEach(function (element) {
        if (element <= number && element !== 0 && element !== '+') {
          return
        }

        $('.second').append('<option value="' + element + '"' + (element === second_number ? 'selected' : '') + '>' + ((element === 0 || element === '+') ? (element === 0 ? '-' : '+') : element + ' players') + '</option>')
      })
    }

    var recommended_players = number

    if (number !== 0 && number !== 10 && second_number) {
      if (second_number === '+') {
        recommended_players += second_number
      } else {
        recommended_players += '-' + second_number
      }
    }

    $('.recommended_players').val(recommended_players)
  }

  var recommended_players_element = $('#recommended_players')

  if (recommended_players_element !== undefined && recommended_players_element !== null && recommended_players_element.val() !== '') {
    var value = recommended_players_element.val()

    if (value !== undefined && value !== null) {
      if (value.indexOf('-') > -1 || value.indexOf('+') > -1) {
        var split
        var one
        var two

        if (value.indexOf('+') > -1) {
          split = value.split('+')
          one = split[0]
          two = '+'
        } else {
          split = value.split('-')
          one = split[0]
          two = split[1]
        }

        $('.first').val(one)
        $('.second').val(two)
        validate_recommended_players(one, two)
      } else {
        $('.first').val(value)
        validate_recommended_players(value, 0)
      }
    }
  }

  var recommended_players = function (event) {
    validate_recommended_players($('.first').val(), $('.second').val())

    do_validation(event)
  }

  $('#upload .first').change(recommended_players)
  $('#upload .second').change(recommended_players)

  $('#upload').submit(function (event) {
    var values = validate.collectFormValues($('#upload'))
    var form = $(this)

    $.post('/validate', values).done(function (data) {
      $('#upload').unbind('submit')
      form.submit()
    })

    return false
  })

  var do_validation_edit = _.debounce(function (event) {
    // TODO Replace this to get rid of validator.js dependency
    var values = validate.collectFormValues($('#edit'))
    latest_timestamp = event.timeStamp

    $.post('/validate/edit', values).done(function (data) {
      if (latest_timestamp > event.timeStamp) {
        return
      }

      $('span.error').hide()
      $('input[type=\'submit\']').prop('disabled', false)
    }).fail(function (error) {
      if (latest_timestamp > event.timeStamp) {
        return
      }

      $('span.error').hide()
      if (error.status === 400 && error.responseJSON.fields) {
        for (var field in error.responseJSON.fields) {
          // TODO Only display errors below untouched fields? Or do like DigitalOcean and put a list at the bottom
          $('span.' + field).html(error.responseJSON.fields[field]).show()
        }
      }

      $('input[type=\'submit\']').prop('disabled', true)
    })
  }, 300)

  $('#edit .validate').on('keyup', do_validation_edit)
  $('#edit .thumbnail').change(do_validation_edit)
  $('#edit select').change(do_validation_edit)

  $('#edit .replace-thumbnail').on('click', function (event) {
    $('.thumbnail').show()
    $('.thumbnail-previous').remove()
    $('.thumbnail_preview').css('background-image', '')
    $('.thumbnail_preview').hide()
    $('.replace-thumbnail').remove()
    do_validation_edit(event)
  })

  var do_validation_edit_map = _.debounce(function (event) {
    // TODO Replace this to get rid of validator.js dependency
    var values = validate.collectFormValues($('#edit_map'))
    latest_timestamp = event.timeStamp

    $.post('/validate/edit_map', values).done(function (data) {
      if (latest_timestamp > event.timeStamp) {
        return
      }

      $('span.error').hide()
      $('input[type=\'submit\']').prop('disabled', false)
    }).fail(function (error) {
      if (latest_timestamp > event.timeStamp) {
        return
      }

      $('span.error').hide()
      if (error.status === 400 && error.responseJSON.fields) {
        for (var field in error.responseJSON.fields) {
          // TODO Only display errors below untouched fields? Or do like DigitalOcean and put a list at the bottom
          $('span.' + field).html(error.responseJSON.fields[field]).show()
        }
      }

      $('input[type=\'submit\']').prop('disabled', true)
    })
  }, 300)

  $('#edit_map .validate').on('keyup', do_validation_edit_map)
  $('#edit_map .input-group label').click(do_validation_edit_map)

  var give_realm = function () {
    var button = $(this)

    $.post('/user/realm', { user: $(this).parents('.user-box').data('user') }).success(function (data) {
      // TODO Indicator that it actually did something?
      button.text('Extend realm').removeClass('btn-success').addClass('btn-default')
    })
  }

  $('.give-realm').on('click', give_realm)

  var disable = function (event) {
    var container = $(this).parents('.user-container')

    $.ajax({
      url: '/user/' + $(this).parents('.user-box').data('user'),
      type: 'DELETE'
    }).success(function (data) {
      container.find('.disable-user').removeClass('disable-user btn-danger').addClass('enable-user btn-success').text('Enable user').unbind('click').bind('click', enable)
      container.find('.give-realm').remove()
    })
  }

  $('.disable-user').on('click', disable)

  var enable = function (event) {
    var container = $(this).parents('.user-container')

    $.ajax({
      url: '/user/' + $(this).parents('.user-box').data('user'),
      type: 'PUT'
    }).success(function (data) {
      container.find('.btn-group').prepend('<button class="btn btn-default give-realm">Give realm</button>').bind('click', give_realm)
      container.find('.enable-user').addClass('disable-user btn-danger').removeClass('enable-user btn-success').text('Disable user').unbind('click').bind('click', disable)
    })
  }

  $('.enable-user').on('click', enable)

  $('#upload .replace-thumbnail').on('click', function (event) {
    $('.thumbnail').show()
    $('.thumbnail-previous').remove()
    $('.thumbnail_preview').css('background-image', '')
    $('.thumbnail_preview').hide()
    $('.replace-thumbnail').remove()
    do_validation(event)
  })

  $('#upload .replace-resourcepack').on('click', function () {
    $('.resourcepack-field').show()
    $('.resourcepack-previous').remove()
    $('.replace-resourcepack').remove()
    do_validation(event)
  })

  var recommended_players_edit = function (event) {
    validate_recommended_players($('.first').val(), $('.second').val())
    do_validation_edit(event)
  }

  $('#edit .first').change(recommended_players_edit)
  $('#edit .second').change(recommended_players_edit)
})
